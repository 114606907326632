import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We had a great group of speakers and attendees at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io"
      }}>{`our`}</a>{` `}<a parentName="p" {...{
        "href": "http://sb.dashbot.io"
      }}>{`SuperBot`}</a>{` Conference this year.`}</p>
    <p>{`Below are Q`}{`&`}{`A videos with some of the attendees at the event.`}</p>
    <h4>{`Angik Sarkar, TheWaylo`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/um7hi07cv_o?feature=oembed" width="1200"></iframe>
    <h4>{`Animata Guha, IBM Watson`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/dSEl-OC3oKo?feature=oembed" width="1200"></iframe>
    <h4>{`Ben Brown, Howdy`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/8LXI9scGTWk?feature=oembed" width="1200"></iframe>
    <h4>{`Carolyn Chong, Obie.ai`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/UYJ66WTXvPQ?feature=oembed" width="1200"></iframe>
    <h4>{`Dennis Yang, Dashbot`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/oUktR6i00c4?feature=oembed" width="1200"></iframe>
    <h4>{`Dmitrii Dumik, Chatfuel`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/mAg6SvkVRsk?feature=oembed" width="1200"></iframe>
    <h4>{`Greg Leuch, Poncho`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/I9ZiBjnc5dU?feature=oembed" width="1200"></iframe>
    <h4>{`Harshal Dhir, Machaao`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/sn2loenKrMs?feature=oembed" width="1200"></iframe>
    <h4>{`Lauren Kunze, Pandorabots`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/K3jUDUqS9oM?feature=oembed" width="1200"></iframe>
    <h4>{`Liana Dumitru, Memo.ai`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/3n7-eLwnym4?feature=oembed" width="1200"></iframe>
    <h4>{`Lucas Ives, Pullstring`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/6GFBy2UfhpI?feature=oembed" width="1200"></iframe>
    <h4>{`Peter Buchroithner, Swell`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/aEbaEyfDNQA?feature=oembed" width="1200"></iframe>
    <h4>{`Vera Tzoneva, Google`}</h4>
    <iframe allow="autoplay; encrypted-media" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/vLRVzWn0KO8?feature=oembed" width="1200"></iframe>
    <h3>{`Until next time…`}</h3>
    <p>{`Stay tuned for our next SuperBot conference in 2018.`}</p>
    <p>{`In the meantime, come to any of our monthly meetups in either SF or NYC.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, Twitter, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      